import { CognitoUserPool } from "amazon-cognito-identity-js";

const config = {
  region: process.env.REACT_APP_CGO_GRPUSR_ZONE || "eu-central -1",
  UserPoolId: process.env.REACT_APP_CGO_GRPUSR_ID || "eu-central-1_LKOvahkqY",
  UserPoolWebClientId: process.env.REACT_APP_CGO_GRPUSR_CID || "7jkln9l9s8lsv95r5okpu9ue4e",
  ClientId: process.env.REACT_APP_CGO_GRPUSR_CID || "7jkln9l9s8lsv95r5okpu9ue4e",
};

console.log({config})

const userPool = new CognitoUserPool(config);

export default userPool;
